.noHoverBtn{
    background-color: #0259ff;
    border-color: #0259ff;
    outline: none;
    box-shadow :none;
}

.noHoverBtn:hover{
    background-color: #0259ff;
    border-color: #0259ff;
    outline: none;
    box-shadow :none;
}
.noHoverBtn:focus{
    background-color: #0259ff;
    border-color: #0259ff;
    outline: none;
    box-shadow :none;
}
.noHoverBtn:active{
    background-color: #0259ff;
    border-color: #0259ff;
    outline: none;
    box-shadow :none;
}
.noHoverBtn:active:focus{
    background-color: #0259ff;
    border-color: #0259ff;
    outline: none;
    box-shadow :none;
}






