@font-face {
    font-family: 'HayasReqular';
    src:  url('/src/assets/fonts/GT-Eesti-Pro-Display-Regular.woff2') format('woff2'),
    url('/src/assets/fonts/GT-Eesti-Pro-Display-Regular.woff2') format('woff2');
}
@font-face {
    font-family: 'HayasBold';
    src:  url('/src/assets/fonts/GT-Eesti-Pro-Display-Bold.woff2') format('woff2'),
    url('/src/assets/fonts/GT-Eesti-Pro-Display-Bold.woff2') format('woff2');
}
@font-face {
    font-family: 'HayasMedium';
    src:  url('/src/assets/fonts/GT-Eesti-Pro-Display-Medium.woff2') format('woff2'),
    url('/src/assets/fonts/GT-Eesti-Pro-Display-Medium.woff2') format('woff2');
}
@font-face {
    font-family: 'HayasMediumItalic';
    src:  url('/src/assets/fonts/GT-Eesti-Pro-Display-Medium-Italic.woff2') format('woff2'),
    url('/src/assets/fonts/GT-Eesti-Pro-Display-Medium-Italic.woff2') format('woff2');
}

body{
    font-family: HayasReqular;
}

.bg-catalog{
    background-color: #f8f8f8;

}

.group_menu_list{
    color: black;
    padding: 0.4rem;
    text-decoration: none;
    text-transform: none;
}

.group_menu_list:hover{
    color: #0259ff;
    background-color: white;
    cursor: pointer;
}

.group_menu_list:focus{
    color: #0259ff;
    background-color: white;
}

.group_menu_list:active:focus{
    color: #0259ff;
    background-color: white;
}

.a_catalog_list{
    text-transform: none;
    text-decoration: none;
}

.group_name_bold{
    font-family: HayasBold;
    padding: 0;
    margin: 0;
}

.sub_category{
    padding-top: 0.4rem;
    cursor: pointer;
}

.sub_category:hover{
    color: #0259ff;

}
.container-sub-groups{
    background-color: white;
}
.ul-margin-top{
    margin-top: 0.3rem;
}
.horizontal-slider{
    height: 1.7rem;
    padding: 0;
    background-color: #6c6c6c14;
    appearance: none;
    border-radius: 3rem;
    width: 96%;
}
.example-track-0{
    height: 1.5rem;
    border-radius: 10rem;
    color: white;
}
.example-track-2{
    height: 1.5rem;
    border-radius: 10rem;
    color: white;
}
.example-thumb-0{
    color: white;
    background-color: #1b4d96;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    min-width: 1.5rem;
    padding: 0.1rem;
}
.example-thumb-1{
    color: white;
    background-color: #1b4d96;
    border-radius: 5px;
    min-width: 1.5rem;
    text-align: center;
    cursor: pointer;
    padding: 0.1rem;
}
