@font-face {
    font-family: 'HayasReqular';
    src:  url('/src/assets/fonts/GT-Eesti-Pro-Display-Regular.woff2') format('woff2'),
    url('/src/assets/fonts/GT-Eesti-Pro-Display-Regular.woff2') format('woff2');
}
@font-face {
    font-family: 'HayasBold';
    src:  url('/src/assets/fonts/GT-Eesti-Pro-Display-Bold.woff2') format('woff2'),
    url('/src/assets/fonts/GT-Eesti-Pro-Display-Bold.woff2') format('woff2');
}
@font-face {
    font-family: 'HayasMedium';
    src:  url('/src/assets/fonts/GT-Eesti-Pro-Display-Medium.woff2') format('woff2'),
    url('/src/assets/fonts/GT-Eesti-Pro-Display-Medium.woff2') format('woff2');
}
@font-face {
    font-family: 'HayasMediumItalic';
    src:  url('/src/assets/fonts/GT-Eesti-Pro-Display-Medium-Italic.woff2') format('woff2'),
    url('/src/assets/fonts/GT-Eesti-Pro-Display-Medium-Italic.woff2') format('woff2');
}

body{
    font-family: HayasReqular;
}


.bg-footer{
    background-color: #f8f8f8;
    margin-top: 0.5rem;
}
.footer-title-bold{
    font-family: HayasBold;
}
.img_logo_footer{
    width: 10rem;
}
