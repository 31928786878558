@font-face {
    font-family: 'HayasReqular';
    src:  url('/src/assets/fonts/GT-Eesti-Pro-Display-Regular.woff2') format('woff2'),
    url('/src/assets/fonts/GT-Eesti-Pro-Display-Regular.woff2') format('woff2');
}
@font-face {
    font-family: 'HayasBold';
    src:  url('/src/assets/fonts/GT-Eesti-Pro-Display-Bold.woff2') format('woff2'),
    url('/src/assets/fonts/GT-Eesti-Pro-Display-Bold.woff2') format('woff2');
}
@font-face {
    font-family: 'HayasMedium';
    src:  url('/src/assets/fonts/GT-Eesti-Pro-Display-Medium.woff2') format('woff2'),
    url('/src/assets/fonts/GT-Eesti-Pro-Display-Medium.woff2') format('woff2');
}
@font-face {
    font-family: 'HayasMediumItalic';
    src:  url('/src/assets/fonts/GT-Eesti-Pro-Display-Medium-Italic.woff2') format('woff2'),
    url('/src/assets/fonts/GT-Eesti-Pro-Display-Medium-Italic.woff2') format('woff2');
}

body{
    font-family: Century Gothic Std,arial,sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

.comment-textarea{
    width: 50%;
}

@media (min-width: 1400px){
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1416px;
    }
}

.bg-register-container{
    background-color: #e9e9e9;
}
.old_price{
    color: grey;
    font-size: 0.9rem;
    text-decoration: line-through;
}

.text_price{
    /*background-color: #10c44c;*/
    color: black;
    padding: 0.2rem;
    margin-right: 0.2rem;
    font-family: HayasBold;
    font-size: 1.2rem;
}

.header-search-button{
    background-color: #1b4d96;
    border-color: #1b4d96;
}

.header-search-button:focus{
    background-color: #1b4d96;
    border-color: #1b4d96;
}

.page-item.active .page-link {
    background-color: #1b4d96;
    border-color: #1b4d96;
    color: white !important;
}

.text-color{
    color: black !important;
}

.page-link {
    color: #1b4d96 !important;
}

a{
    color: #1b4d96 !important;
}

.header-search-button:hover{
    background-color: #1b4d96;
    border-color: #1b4d96;
}

.noHoverBtn{
    background-color: #1b4d96;
    border-color: #1b4d96;
}

.noHoverBtn:hover, .noHoverBtn:focus, .noHoverBtn:active, .noHoverBtn:focus-within, .noHoverBtn:target, .noHoverBtn:focus-visible{
    background-color: #1b4d96 !important;
    border-color: #1b4d96 !important;
}

.border-dark{
    border: 1px solid black;
}

.card-body{
    padding: 0rem 0rem
}
.card_title{
    margin-top: 0.4rem;
}
.addToCard{
    margin-bottom: 0.3rem;
    bottom: 0;
    position: absolute;
    z-index: 1;
    background-color: white;
    border-color: black;
    color: black;
}

.addToCard:hover, .cart-button-product:hover{
    background-color: rgb(242, 242, 242);
    color: black;
    border-color: black;
}

.one-click{
    right: 2%;
    background-color: black;
    color: white;
}

.cart-button-product{
    background-color: #ffd814 !important;
    border-color: #ffd814;
    color: #000!important;
    width: 200%;
}

.one-click:hover{
    background-color: black;
    color: white;
}

.product_img_main{
    display: block;
    width: 100%;
    height: 55%;
    object-fit: contain;
    cursor: pointer;
}
.card_product{
    width: 13.6rem;
    height: 24rem;
    /*margin: 0.3rem;*/
    margin-bottom: 1rem;
    padding: 0.5rem;
}
.link-cart{
    color: black;
    text-decoration: auto;
    margin: 0;
    padding: 0;
}

p, span, h1, h2, h3, h4, h5, h6, a{
    font-family: Century Gothic Std,arial,sans-serif !important;
}

.text-primary{
    color: #1b4d96 !important;
}

.nav-link{
    color: #1b4d96 !important;
}

.nav-link:focus{
    color: black !important;
}

.tab-content{
    background-color: #f8f8f8;
}

#cart-color{
    background-color: #f8f9fa !important; 
    color: #ff0205 !important;
    font-size: 20px;
    position: relative;
    left: 0px;
}

.width-16{
    width: 16%;
}

.width-20{
    width: 20%;
}

.drop-menu{
    position: absolute !important;
}

.btn-white{
    background-color: white;
    color: black;
    border-color:black ;
}

.banner-btn{
    position: absolute;
    top: 74%;
    right: 6%;
    font-size: 148%;
    opacity: 0.9;
}

.header-search{
    width: 160%;
    
}

.header-search-input:focus{
    outline: none !important;
    box-shadow: none;
}

.search-group{
    position: absolute;
    top: 112%;
    z-index: 1000;
    width: 100%;
    max-height: 280px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    border: solid black 0.9px;
    border-top: none;
}

.footer-link{
    text-decoration: none;
    color: black !important;
}

.filter-show{
    display: none;
}

.catalog-button{
    margin-top: 0px;
}

.btn {
    border-radius: 7px;
    font-family: Century Gothic Std,arial,sans-serif;
}

.cart_text{
    position: relative;
    top: -10px;
    left: -13px;
    background-color: #1b4d96;
    border-radius: 50%;
    /* align-items: center; */
    width: 22px;
    height: 19px;
    text-align: center;
    font-size: 13px;
    color: white;
    font-weight: 900;
}

@media (min-width: 992px) {
    /*.catalog-button, .header-search{
        height: 39px;
        margin-top: 11px;
    }*/

    .card-span{
        position: relative;
        left: -16px;
        
        background-color: red;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: white;
        font-weight: bold;
    }

    .header-search{
        width: 67%;
    }

    .card_product{
        width: 9.5rem;
    }

    .addToCard{
        font-size: 10px;
    }
}

@media (min-width: 1200px) {
    /*.catalog-button, .header-search{
        height: 39px;
        margin-top: 11px;
    }*/

    
    .header-search{
        width: 100%;
    }
    .card_product{
        width: 11.4rem;
    }

    .addToCard{
        font-size: 13px;
    }
}

@media (min-width: 1440px) {
    /*.catalog-button, .header-search{
        height: 39px;
        margin-top: 11px;
    }*/

    
    
    .header-search{
        width: 160%;
    }

    .card_product{
        width: 13.8rem;
    }

    .addToCard{
        font-size: 17px;
    }
}




.star-margin{
    margin-right: 2px;
}

.header-icons{
    justify-content: end;
    margin-left: 2%;
}
 
@media only screen and (max-width: 600px) {
    .filter-show{
        display: block;
    }
    .cart-button-product{
        width: 135%;
    }
    .card-span{
        position: relative;
        left: -16px;
        
        background-color: red;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: white;
        font-weight: bold;
    }

    .mob-cart-icon{
        width: 13%;
    }

    .addToCard, .one-click{
        font-size: 12px;
    }

    .hide-filter-mobile{
        display: none;
    }

    .search-group{
        max-height: 200px;
        border: solid black 0.1px;
        border-top: none;
    }
    
    .card_product{
        width: 10.7rem;
    }
    
    .banner-btn{
        position: absolute;
        top: 62%;
        right: 6%;
        font-size: 100%;
        
    }
    

    .mob-card{
        width: 50%;
    }

    .catalog-button{
        width: 30%;
        
        margin-top: 2%;
        margin-bottom: 2%;
        
    }

    .header-icons{
        margin-top: 2%;
        justify-content: space-between;
        margin-left: 0;
    }

    #cart-color{
        left: -20px;
    }

    .margin-out{
        padding-left: 0;
        padding-right: 0;
    }    

    .comment-textarea{
        width: 100%;
    }

    .header-search{
        width: 100%;
    }
}